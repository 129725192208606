
.fog{
    /* background-color: rgb(255, 255, 255, 0.5); */
    backdrop-filter: blur(20px);
}

.box {
    display: flex;
    flex-flow: column;
    height: 100%;
}
.box .row.header {
    flex: 0 1 auto;
}
.box .row.content {
    flex: 1 1 auto;  
}
.box .row.footer {
    flex: 0 1 0px;
}
.box-container {
    display: contents;
}

.grid_Container{
    position: absolute;
    width: 100%;
    top: 100px;
    bottom: 0px;
    max-height: 100%;
    /* border: 3px solid green; */
}

.grid_layout{
    display: grid;
    height: 100%;
    flex: 1;
    grid-template-columns: 1fr 6fr;
    justify-content: center;
}


.content_holder{
    flex: 1;
    margin: 10px;
    padding: 10px;
    overflow: auto;
    overflow-y: scroll;
}



.nameStyle{
    color: rgb(62, 155, 231);
    font-weight: 300;
    font-size: 50px;
    float: left;
    position: static;
    opacity: 1;
    padding: 10px 20px;
    font-family: 'Verdana';
}

.sidebar_button_text{
    color: rgb(120, 186, 240);
    font-weight: 300;
    font-size: 40px;
    font-family: 'Verdana';
}

.sidebar_button_container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 5px;
}



.sidebar_buttons{
    background-color: rgba(255, 255, 255, 0);
    border-radius: 5px;
    outline: 0px;
    border: none;
    height: 60px;
    transition: ease background-color 250ms;
    &:hover{
        background-color: rgba(255, 255, 255, 0);
        box-shadow: inset 0px 0px 0px 2px lightgrey;
        /* box-shadow: 5px 5px 5px 0px lightgrey; */
    }
    /* responsive, form small screens, use 13px font size */

}




.hyperlink_button{
    background-color: rgba(255, 255, 255, 0);
    color: black;
    margin: 5px 15px;
    border-radius: 20px;
    box-shadow: 2px 2px 2px 0px lightgray;
    outline: 0px;
    border: none;
    height: 60px;
    width: 200px;
    transition: ease background-color 250ms;
    &:hover{
        background-color: rgba(185, 221, 245, 0.1);
        box-shadow: inset 2px 2px 2px 2px lightgrey;
    }

}

.hyperlink_button_text{
    color: rgb(120, 186, 240);
    font-weight: 200;
    font-size: 30px;
    font-family: 'Verdana';
}



.project_Title{
    font-size: 50px;
    text-shadow: 1px 1px lightgray;
    font-family: 'Verdana';
    color: rgba(255, 255, 255, 0.9);
}

.portfolio_heading{
    font-size: 30px;
    /* text-shadow: 2px 2px lightgray; */
    font-family: 'Verdana';
    color: rgba(255, 255, 255, 0.9);
}

.portfolio_text{
    padding: 0px;
    font-size: 18px;
    color: rgba(255, 255, 255, 0.9);
    /* text-shadow: 1px 1px lightgray; */
    font-family: 'Verdana';
    /* background-color: pink; */
}

.skillName{
    text-align: center;
    vertical-align: middle;
    height: 100%;
}


.resumeGrid{
    display: grid;
    padding: 5px;
    row-gap: 10px;
    /* background-color: pink */
}

.gridRow{
    display: grid;
    height: 100%;
    flex: 1;
    grid-template-columns: 1fr 7fr;
    justify-content: center;
    column-gap: 10px;
    /* background-color: rgb(196, 90, 196); */
}

.aboutRow{
    display: grid;
    padding: 10px;
    height: 100%;
    flex: 1;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
}

.projectsGridRow{
    display: grid;
    padding: 5px;
    height: 100%;
    flex: 1;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: center;
    column-gap: 20px;
}


.projectCard{
    background-color: rgb(255, 255, 255, 0.1);
    border-radius: 5px;
    box-shadow: 2px 2px 2px 0px lightgray;
    outline: 0px;
    border: none;
    height: 450px;
    width: 100%;
    transition: ease background-color 250ms;
    &:hover{
        background-color: rgb(255, 255, 255, 0);
        box-shadow: 5px 5px 5px 0px lightgrey;
    }
}

.cardsplit{
    display: grid;
    margin: 5px 0px 0px 0px;
    width: auto;
    height: 100%;
    flex: 1;
    grid-template-rows: 7fr 3fr;
    justify-content: center;
}

.card_topHalf{
    height: 100%;
    width: 100%;
    /* background-color: rgb(38, 55, 216); */

}

.card_bottomHalf{
    height: 100%;
    width: 100%;    
    /* background-color: rgba(216, 38, 83, 0.89); */
    
}

.cardTitle{
    color: rgba(255, 255, 255, 0.9);
    font-size: 30px;
    font-weight: 300;
    width: 100%;
}

.cardSubtitle{
    color: rgba(255, 255, 255, 0.8);
    font-weight: 100;
    font-size: 18px;
    width: 300px;
  
}

.lineBreak{
    margin: 2px;
    color: rgb(120, 186, 240);
    background-color: rgb(120, 186, 240);
    height: 2px;
    grid-column: span 2;
}


.myPhoto{
    width: 300px;    
}



.photoContainer{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.toolboxRow{
    display: grid;
    padding: 5px;
    height: 100%;
    flex: 1;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;

}

.projectPhoto{
    height: 300px;
}

.projects_page_GridRow{
    display: grid;
    padding: 5px;
    height: 100%;
    width: 100%;
    flex: 1;
    row-gap: 5px;
    grid-template-columns: 1fr 1fr 1fr  ;
    justify-content: center;
    align-content: center;
    column-gap: 20px;
}

.projects_Experiencepage_GridRow{
    display: grid;
    padding: 5px;
    width: 100%;
    flex: 1;
    row-gap: 5px;
    grid-template-columns: 1fr 1fr;
    justify-content: middle;
    align-content: middle;

}

.projectPhoto_zineCovers{
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column: span 2;
}

.projectPhoto_zineLEFT{
    width: 100%;
    height: 300px;
    display: flex;
    align-items: end;
    justify-content: end;

}

.projectPhoto_zineRIGHT{
    width: 100%;
    height: 300px;
    display: flex;
    align-items: start;
    justify-content: start;

}

.zinePhoto{
    height: 300px;
}

.zineRow{
    display: grid;
    padding: 5px;
    width: 100%;
    flex: 1;
    row-gap: 5px;
    grid-template-columns: 1fr 1fr;
    justify-content: middle;
    align-content: middle;
}


.videoSize{
    height:500px;
    width:900px;

}

.listColour{
    color: rgba(255, 255, 255, 0.2);

}

.projectCard_ImageStyle{
    background-size: 'cover';
    max-width: 300px;
    max-height: 300px;
    width: 100%; 
    height: 100%; 
}








































@media only screen and (max-width: 1800px) {
    div.projectsGridRow{
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}


@media only screen and (max-width: 1560px) {
    div.projectsGridRow{
        grid-template-columns: 1fr 1fr 1fr;
    }


}

@media only screen and (max-width: 1200px) {
    div.projectsGridRow{
        grid-template-columns: 1fr 1fr ;
    }
}

@media only screen and (max-width: 900px) {
    div.projectsGridRow{
        grid-template-columns: 1fr  ;
    }

    div.projects_page_GridRow{
        grid-template-columns: 1fr 1fr  ;
    }

    div.projects_Experiencepage_GridRow{
        grid-template-columns: 1fr ;
    }

    div.nameStyle{
        align-items: center;
        float: center;
        width: 100%;
    
    }
}


@media only  screen and (max-width: 960px){
    
/*
    About Styling
*/      
    
    img.myPhoto{
        display: none;
    }

    div.aboutRow{
        grid-template-columns: 1fr;
    }
 
/*
    Project Cards
*/  


    h1.cardTitle{
        font-size: 20px;
        font-weight: 300;

    }

    h2.cardSubtitle{
        font-weight: 200;
        font-size: 14px;
        display: none;
    }

    div.projectsGridRow{
        grid-template-columns: 1fr 1fr  ;
        column-gap: 2px;
    }

    img.projectPhoto{
        width: 100px;
        height: auto;
    }


    button.projectCard{
        margin: 1px 1px;
        border-radius: 5px;
        height: auto;
        width: 100%;
        max-width: 250px;
    }

    
    img.projectCard_ImageStyle{
        max-width: 225px;
        max-height: 225;
    }

    div.cardsplit{
        grid-template-rows: 8fr 2fr;
    }
/*
    Video Size
*/  
    iframe.videoSize{
        height:280px;
        width:500px;
    }
/*
    Zine Stuff
*/ 

    div.projectPhoto_zineLEFT{
        height: 130px;
    }
    
    div.projectPhoto_zineRIGHT{
        height: 130px;
    }

    div.projectPhoto_zineCovers{
        height: 130px;
    }

    img.zinePhoto{
        height: 130px;
    }

    div.zineRow{
        padding: 1px;
        flex: 1;
        row-gap: 5px;
    }

/*
    Text Sizes
*/

    text.sidebar_button_text{
        font-size: 30px;
    }
    h1.project_Title{
        font-size: 26px;
    }

    h1.portfolio_heading{
        font-size: 22px;
    }

    h2.portfolio_heading{
        font-size: 22px;
    }

    text.portfolio_text{
        font-size: 18px;
    }

}



@media only screen and (max-width:700px) {

    div.sidebar_button_container{
        flex-direction: row;
        align-items: middle;
        padding: 1px;
    }

    div.sidebar_buttons{
        justify-content: center;
        column-gap: 20px;
    }

    div.grid_layout {
        grid-template-columns: none;
    }

    div.sidebar_button_container{
        flex-direction: row;
        align-items: middle;
        padding: 1px;
    }


    img.myPhoto{
        display: none;
    }

    div.aboutRow{
        grid-template-columns: 1fr;
    }
    
/*
    Video Size
*/   
    iframe.videoSize{
        height:225px;
        width:400px;
    }

/*
    Text Sizes
*/

    text.sidebar_button_text{
        font-size: 35px;
    }
    h1.project_Title{
        font-size: 24px;
    }

    h1.portfolio_heading{
        font-size: 18px;
    }

    h2.portfolio_heading{
        font-size: 18px;
    }

    text.portfolio_text{
        font-size: 14px;
    }
}



@media only screen and (max-width: 500px) {
    div.grid_layout {
        grid-template-columns: none;
    }

    div.sidebar_button_container{
        flex-direction: row;
        align-items: middle;
        padding: 0px;
        justify-content: center;
        column-gap: 20px;
    }

    div.sidebar_buttons{
        justify-content: center;
        column-gap: 20px;
    }

    img.myPhoto{
        display: none;
    }

    div.aboutRow{
        grid-template-columns: 1fr;
    }
    
    iframe.videoSize{
        height:225px;
        width:400px;
    }


    div.grid_layout {
        grid-template-columns: none;
    }


    text.sidebar_button_text{
        font-size: 25px;
    }

    div.sidebar_button_container{

    }

    h1.project_Title{
        font-size: 24px;
    }

    h1.portfolio_heading{
        font-size: 18px;
    }

    h2.portfolio_heading{
        font-size: 18px;
    }


    text.portfolio_text{
        font-size: 14px;
    }

    img.myPhoto{
        display: none;
    }

    div.aboutRow{
        grid-template-columns: 1fr;
    }
    
    iframe.videoSize{
        height:195px;
        width:350px;
    }

    div.projectsGridRow{
        grid-template-columns: 1fr 1fr  ;
        column-gap: 2px;
    }

    img.projectPhoto{
        width: 350px;
        height: auto;
    }

    div.projects_Experiencepage_GridRow{
        grid-template-columns: 1fr ;
    }

    button.projectCard{
        margin: 1px 1px;
        border-radius: 5px;
        height: 225px;
        width: 100%;
        max-width: 180px;

    }


    h1.cardTitle{
        font-size: 20px;
        font-weight: 300;

    }

    h2.cardSubtitle{
        font-weight: 100;
        font-size: 14px;
        display: none;
    }

    div.content_holder{
        margin: 3px;
        padding: 3px;
    }

    img.projectCard_ImageStyle{
        max-width: 150px;
        max-height: 150px;
    }

    div.projectPhoto_zineLEFT{
        height: 130px;
    }
    
    div.projectPhoto_zineRIGHT{
        height: 130px;
    }

    div.projectPhoto_zineCovers{
        height: 130px;
    }

    img.zinePhoto{
        height: 130px;
    }

    div.zineRow{
        padding: 1px;
        flex: 1;
        row-gap: 5px;
    }

    div.projects_page_GridRow{
        grid-template-columns: 1fr;
    }

    div.nameStyle{
        padding: 1px 20px;
    }
}


